(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-application"), require("core-utils"));
	else if(typeof define === 'function' && define.amd)
		define("anchor-navigation-list", ["core-application", "core-utils"], factory);
	else if(typeof exports === 'object')
		exports["anchor-navigation-list"] = factory(require("core-application"), require("core-utils"));
	else
		root["anchor-navigation-list"] = factory(root["core-application"], root["core-utils"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__424__, __WEBPACK_EXTERNAL_MODULE__139__) => {
return 