import {signal} from 'core-application';
import {dynamicSmoothScroll} from 'core-application';
import {dom} from 'core-utils';
import AnchorList from './anchor-list';

export default class AnchorNavigationListElement extends HTMLElement {
	constructor() {
		super();
		this.navigationInitialWidth = 0;
		this.stickyPosition = 0;
		this.windowScrollPosition = 0;
		this.mutationObserver = null;
	}

	/**
	 * connectedCallback
	 * @returns {void}
	 */
	connectedCallback() {
		this._initialize();
		this._addEventListener();
	}

	/**
	 * disconnectedCallback
	 * @returns {void}
	 */
	disconnectedCallback() {
		this._removeEventListener();

		// disconnect mutation observer and allow garbage collection
		if (this.mutationObserver) {
			this.mutationObserver.disconnect();
			this.mutationObserver = null;
		}
	}

	_initialize() {
		this._initializeNavigation();
		this._initializeFlyOutButton();
		this._initializeAnchorLinks();

		this._prepareNavigationDisplay();

		this._observeFaVisualizerChanges();
	}

	/**
	 * @returns {void}
	 */
	_addEventListener() {
		let eventBus = signal.getEmitter();

		this.onScrollHandler = dom.throttle(this._onScrollHandler.bind(this), 100);
		window.addEventListener('scroll', this.onScrollHandler);

		this.onResizeHandler = dom.throttle(this._onResizeHandler.bind(this), 100);
		window.addEventListener('resize', this.onResizeHandler);

		eventBus.addListener(AnchorList.EVENT_ANCHOR_ACTIVATED, this._onActivateNavigationAnchor.bind(this));
	}

	_removeEventListener() {
		const anchorLinksLength = this.anchorLinks.length;

		this.flyOutButton.removeEventListener('click', this._onToggleFlyoutStatus);

		for (let index = 0; index < anchorLinksLength; index++) {
			this.anchorLinks[index].removeEventListener('click', this._onClickAnchorLink);
		}

		this.anchorList.removeEventListener();

		window.removeEventListener('scroll', this.onScrollHandler);
		window.removeEventListener('resize', this.onResizeHandler);
	}

	/**
	 * initialize the navigation elements
	 * @returns {void}
	 */
	_initializeNavigation() {
		this.navigation = this.querySelector('.nm-anchor-navigation-list__navigation-list');
		this.navigationWrapper = this.querySelector('.nm-anchor-navigation-list__navigation-wrapper');

		if (this.navigation) {
			this.navigationInitialWidth = this.navigation.scrollWidth;
		}
	}

	/**
	 * @returns {void}
	 */
	_prepareNavigationDisplay() {
		if (this.navigation) {
			this._calculateNavigationType();
			this._calculateStickyPosition();
			this._handleStickyPositioning();
		}
	}

	/**
	 * checks whether the navigation fits in the available space or the flyout menu is to be shown
	 * @returns {void}
	 */
	_calculateNavigationType() {
		let availableWidthForNavigation =
		this.navigationWrapper.clientWidth + 1;

		let wrapperPadding = window
			.getComputedStyle(this.navigationWrapper)
			.getPropertyValue("padding-left");
		let wrapperPaddingNumber = parseFloat(wrapperPadding) * 2;

		let availableWidthForNavigationMinusPadding =
		availableWidthForNavigation - wrapperPaddingNumber;

		if (
			availableWidthForNavigationMinusPadding >=
		this.navigationInitialWidth
		) {
			if (this.isFlyout) {
				this.isFlyout = false;
			}
		}
		else if (!this.isFlyout) {
			this.isFlyout = true;
		}
	}

	/**
	 * initialize the flyout button
	 * @returns {void}
	 */
	_initializeFlyOutButton() {
		this.flyOutButton = this.querySelector('.nm-anchor-navigation-list__flyout-button');
		this._onToggleFlyoutStatus = this._onToggleFlyoutStatus.bind(this);

		if (this.flyOutButton) {
			this.flyOutButton.addEventListener('click', this._onToggleFlyoutStatus);
		}
	}

	/**
	 * initialize the anchor navigation links
	 * @returns {void}
	 */
	_initializeAnchorLinks() {
		this.anchorLinks = this.querySelectorAll('.nm-anchor-navigation-list__link');

		let anchors = this._getAnchorIds();

		if (!anchors || anchors.length === 0) {
			return;
		}

		this.anchorList = new AnchorList(anchors);

		this._onClickAnchorLink = this._onClickAnchorLink.bind(this);

		for (let index = 0; index < this.anchorLinks.length; index++) {
			this.anchorLinks[index].addEventListener('click', this._onClickAnchorLink);
		}
	}

	/**
	 *
	 * @param {CustomEvent} event_ - AnchorList.EVENT_ANCHOR_ACTIVATED
	 * @returns {void}
	 */
	_onActivateNavigationAnchor(event_) {
		let link = this.querySelector("[href='#" + event_.reference + "']");

		if (link) {
			this._deactivateAllAnchorItems();
			this._activateAnchorItem(link);
		}
	}

	/**
	 * handle anchor clicked event
	 * @param {Event} event_ - click event
	 * @returns {void}
	 */
	_onClickAnchorLink(event_) {
		const target = (!event_.target.getAttribute('href')) ? event_.target.parentElement : event_.target;

		const hrefValue = target.getAttribute('href');

		if (!hrefValue || hrefValue.indexOf('#') !== 0) {
			return;
		}

		event_.preventDefault();

		if (this.isFlyoutOpen) {
			this._onToggleFlyoutStatus();
		}

		this._deactivateAllAnchorItems();
		this._scrollToTargetOfLink(event_);
		this._activateAnchorItem(target);
	}

	/**
	 * @param {HTMLElement} link_ - link of item to be activated (click target)
	 * @returns {void}
	 */
	_activateAnchorItem(link_) {
		link_.classList.add('nm-anchor-navigation-list__link--selected');

		this._updateFlyoutLabel(link_.textContent);
	}

	/**
	 * @param {String} text_ text to replace current flyout label text with
	 * @returns {void}
	 */
	_updateFlyoutLabel(text_) {
		this.querySelector('.nm-anchor-navigation-list__flyout-button-label').textContent = text_;
	}

	/**
	 * @returns {void}
	 */
	_deactivateAllAnchorItems() {
		const anchorLinksLength = this.anchorLinks.length;

		for (let key = 0; key < anchorLinksLength; key++) {
			this.anchorLinks[key].classList.remove('nm-anchor-navigation-list__link--selected');
		}
	}

	/**
	 * toggle the open of the flyout
	 * @returns {void}
	 */
	_onToggleFlyoutStatus() {
		this.isFlyoutOpen = !this.isFlyoutOpen;
	}

	/**
	 * @param {Event} event_ - Click Event
	 * @returns {void}
	 */
	_scrollToTargetOfLink(event_) {
		const link_ = (!event_.target.getAttribute('href')) ? event_.target.parentElement : event_.target;
		const targetId = this._getElementID(link_);

		const target = document.getElementById(targetId);

		const context = dynamicSmoothScroll.getScrollContext();

		const startingPosition = (context === document.body)
			? event_.pageY
			: event_.pageY + context.scrollTop;

		const scrollContext = (context === document.body) ? window : context;
		dynamicSmoothScroll.scroll(target, scrollContext, startingPosition);
	}

	/**
	 * @param {string} link_ - link Element
	 * @returns {string} modifiedString - string without starting '#'
	 */
	_getElementID(link_) {
		let modifiedString = link_.getAttribute('href');

		while (modifiedString.charAt(0) === '#') {
			modifiedString = modifiedString.substr(1);
		}

		return modifiedString;
	}

	/**
	 * @returns {void}
	 */
	_resetNavigationType() {
		this.isFlyout = false;
		this.isFlyoutOpen = false;
		this.navigationInitialWidth = this.navigation.scrollWidth;
	}

	/**
	 * handler for the resize evet of the window
	 * @returns {void}
	 */
	_onResizeHandler() {
		this._resetNavigationType();
		this._prepareNavigationDisplay();
	}

	/**
	 * @returns {void}
	 */
	_onScrollHandler() {
		this._handleStickyPositioning();
	}

	/**
	 * @returns {void}
	 */
	_handleStickyPositioning() {
		if (!this.isSticky && window.pageYOffset >= this.windowScrollPosition) {
			this.isSticky = true;
			this.style.top = this.stickyPosition + 'px';
		}
		else if (this.isSticky && window.pageYOffset < this.windowScrollPosition) {
			this.isSticky = false;
			this.style.top = 'initial';
		}
	}

	/**
	 * @returns {void}
	 */
	_calculateStickyPosition() {
		const inpageNavigationElement = document.querySelector('.nm-nav-wrap');
		const currentConsumptionModule = document.querySelector('.nm-module-current-consumption');
		this.stickyPosition = 0;

		if (inpageNavigationElement) {
			this.stickyPosition = inpageNavigationElement.offsetHeight;
		}

		if (currentConsumptionModule) {
			this.stickyPosition += currentConsumptionModule.offsetHeight;
		}

		this.windowScrollPosition = window.pageYOffset + this.getBoundingClientRect().top - this.stickyPosition;
	}

	_observeFaVisualizerChanges() {
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
		const areaElement = document.querySelector(".nm-area-content > #nm-page-title + feature-app");
		if (MutationObserver && areaElement) {
			this.mutationObserver = new MutationObserver(()=> {
				this._calculateStickyPosition();
				this._handleStickyPositioning();
			});

			this.mutationObserver.observe(areaElement, {childList:true, subtree: true});
		}
	}

	/**
	 * collects ids of actual anchor links inside list
	 * @returns {Array} anchorIds - list of anchor ids
	 */
	_getAnchorIds() {
		let anchorIds = [];
		let items = this.querySelectorAll('.nm-anchor-navigation-list__link');

		for (let index = 0; index < items.length; index++) {
			if (items[index]) {
				let href = items[index].getAttribute('href');

				if (href && href.length > 0 && href.indexOf('#') === 0) {
					anchorIds.push(href);
				}
			}
		}

		return anchorIds;
	}

	/**
	 * @returns {HTMLElement} current anchor element
	 */
	_getActiveAnchor() {
		return this.querySelector('.nm-anchor-navigation-list__link--selected');
	}

	get isFlyout() {
		return this.getAttribute('is-flyout') === 'true';
	}
	set isFlyout(val) {
		this.setAttribute('is-flyout', val);
	}

	get isFlyoutOpen() {
		return this.getAttribute('is-flyout-open') === 'true';
	}
	set isFlyoutOpen(val) {
		this.setAttribute('is-flyout-open', val);
	}

	get isSticky() {
		return this.getAttribute('is-sticky') === 'true';
	}
	set isSticky(val) {
		this.setAttribute('is-sticky', val);
	}
}

if (window.customElements.get('audi-anchor-navigation-list') === undefined) {
	window.customElements.define('audi-anchor-navigation-list', AnchorNavigationListElement);
}
