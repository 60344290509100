import {signal} from 'core-application';
import {dom} from 'core-utils';

export default class AnchorList {
	static get EVENT_ANCHOR_ACTIVATED() {
		return 'nm-anchor-navigation-anchor-activated';
	}
	static get MARKER() {
		return .85;
	}

	constructor(idList_) {
		if (!idList_ || idList_.length === 0) {
			return;
		}

		this.anchorIdList = idList_;
		this.anchorList = [];
		this.activeAnchorReference = '';
		this._initialize();
	}

	/**
	 * @returns {void}
	 */
	_initialize() {
		let initialized = this._initializeAnchors();

		if (!initialized) {
			return false;
		}

		let lastClosestAnchorReference = this._calculateClosestAnchorReferenceAboveMarker() || this.firstAnchorInDom.id;
		this._activateAnchorReference(lastClosestAnchorReference);

		this._addEventListener();
	}

	/**
	 * @returns {void}
	 */
	_addEventListener() {
		this.onScrollHandler = dom.throttle(this._onScrollHandler.bind(this), 100);

		window.addEventListener('scroll', this.onScrollHandler);
	}

	/**
	 * @returns {void}
	 */
	removeEventListener() {
		window.removeEventListener('scroll', this.onScrollHandler);
	}

	/**
	 * @returns {boolean} true / false depending on wether any anchors have been found
	 */
	_initializeAnchors() {
		let element;
		let anchors = this._getAnchorsByIds();
		let anchorLength = anchors.length;

		if (!anchors || anchorLength === 0) {
			return false;
		}

		for (let index = 0; index < anchorLength; index++) {
			element = anchors[index];
			this.anchorList[element.id] = element;

			if (!this.firstAnchorInDom || this._isAboveAnchor(element, this.firstAnchorInDom)) {
				this.firstAnchorInDom = element;
			}
		}

		return true;
	}

	/**
	 * @returns {Array} anchors - array with HTMLElements
	 */
	_getAnchorsByIds() {
		let anchors = [];
		let anchorIdLength = this.anchorIdList.length;
		for (let index = 0; index < anchorIdLength; index++) {
			let id = this.anchorIdList[index];
			let element = document.querySelector(id);

			if (element) {
				anchors.push(element);
			}
		}

		return anchors;
	}

	/**
	 *
	 * @param {HTMLElement} anchor1_ - anchor element
	 * @param {HTMLElement} anchor2_ - anchor element
	 * @returns {boolean} whether anchor1 is positioned above anchor2
	 */
	_isAboveAnchor(anchor1_, anchor2_) {
		return anchor1_.getBoundingClientRect().top < anchor2_.getBoundingClientRect().top;
	}

	/**
	 * @returns {void}
	 */
	_onScrollHandler() {
		let lastClosestAnchorReference = this._calculateClosestAnchorReferenceAboveMarker() || this.firstAnchorInDom.id;

		this._activateAnchorReference(lastClosestAnchorReference);
	}

	/**
	 * @returns {string} the closest anchor reference above the marker
	 */
	_calculateClosestAnchorReferenceAboveMarker() {
		let distanceToMarker;
		let lastClosestAnchorReference;
		let lastClosestMarkerDistance = -1;
		let previousAnchorIsStillFullyContainedInViewport = false;

		for (let key in this.anchorList) {
			// eslint-disable-next-line no-prototype-builtins
			if (this.anchorList.hasOwnProperty(key)) {
				distanceToMarker = this._getDistanceToMarker(this.anchorList[key]);

				const actualAnchorIsClosestToMarker = this._isDistanceCloserToMarkerThanLastDistance(distanceToMarker, lastClosestMarkerDistance);

				if (this.anchorList[lastClosestAnchorReference]) {
					previousAnchorIsStillFullyContainedInViewport = this._isAnchorBelowViewportTop(this.anchorList[lastClosestAnchorReference]);
				}

				if (actualAnchorIsClosestToMarker && !previousAnchorIsStillFullyContainedInViewport) {
					lastClosestAnchorReference = key;
					lastClosestMarkerDistance = distanceToMarker;
				}
			}
		}

		return lastClosestAnchorReference;
	}

	/**
	 * @param {string} anchorReference_ - the anchorreference to be activated
	 * @returns {void}
	 */
	_activateAnchorReference(anchorReference_) {
		if (this.activeAnchorReference !== anchorReference_) {
			this.activeAnchorReference = anchorReference_;
			signal.getEmitter().emit(AnchorList.EVENT_ANCHOR_ACTIVATED, {reference: this.activeAnchorReference});
		}
	}

	/**
	 * @param {number} distanceToMarker_ - distance to marker
	 * @param {number} lastClosestMarkerDistance_ - the last closest distance to marker
	 * @returns {boolean} -
	 */
	_isDistanceCloserToMarkerThanLastDistance(distanceToMarker_, lastClosestMarkerDistance_) {
		return distanceToMarker_ >= 0 && (distanceToMarker_ < lastClosestMarkerDistance_ || lastClosestMarkerDistance_ === -1);
	}

	/**
	 *
	 * @param {HTMLElement} anchorElement_ - the anchor elment to be checked
	 * @returns {number} - the distance to the marker
	 */
	_getDistanceToMarker(anchorElement_) {
		const markerPositionInDocument = window.innerHeight * AnchorList.MARKER;

		return markerPositionInDocument - anchorElement_.getBoundingClientRect().top;
	}

	/**
	 *
	 * @param {HTMLElement} anchorElement_ - the anchor elment to be checked
	 * @returns {boolean} - true if the anchor is within the top viewport offest
	 */
	_isAnchorBelowViewportTop(anchorElement_) {
		if (anchorElement_) {
			return anchorElement_.getBoundingClientRect().top > 0;
		}
	}
}
